<template>
  <div class="header">

    <div class="header-left">
      <div class="header__logo">
        <router-link to="/">
          <img src="@/assets/icons/Logo_ined.svg" class="header__logo-img" alt="Логотип">
          <span class="header__logo-text">Интеробразование</span>
        </router-link>
      </div>
      <h1 class="header__title"></h1>
    </div>

    <div class="header-right">
      <div class="header__user-name">{{ nameOrg }}</div>
      <div class="header__logout">
        <v-icon :size="35" color="primary" @click="logout">mdi-logout</v-icon>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HeaderMain",
  methods: {
    ...mapActions('keycloak', {keycloakLogout: 'keycloakLogout'}),

    logout() {
      this.keycloakLogout()
    },
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),

    nameOrg() {
      return this.authData?.given_name ?? 'Название организации';
    },
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.header-left, .header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-right {
  margin-right: 45px;
}

.header__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #00599B;
}

.header__logo-img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.header__logo-text {
  color: #ffffff;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.header__title {
  padding: 0 35px;
  color: #00599B;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
}

.header__user-name {
  color: #00599B;
  text-align: right;
}

.header__logout {
  margin-left: 30px;
  cursor: pointer;
}
</style>