import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/components/MainPage'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/sbor_contacts',
      component: () => import('@/layouts/lkLayout'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/View/ViewLK'),
          meta: {
            requiresAuth: true,
          },
        }
      ]
    },
    {
      path: '/sbor2',
      component: () => import('@/layouts/lkLayout'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/View/sbor2/ViewLK'),
          meta: {
            requiresAuth: true,
            title: 'Предоставление сведений о перечне учебных изданий, указанный в рабочих программах дисциплин (модулей) по истории (истории России, всеобщей истории)'
          },
        }
      ]
    },
    {
      path: '/sbor3',
      component: () => import('@/layouts/lkLayout'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/View/sbor3/ViewLK'),
          meta: {
            requiresAuth: true,
            title: 'Сбор данных по переводу студентов из других стран'
          },
        }
      ]
    },
    {
      path: '/sbor_artificial_intelligence/:year',
      component: () => import('@/View/sborAI/ViewSborAI'),
      props: true,
      meta: {
        requiresAuth: true,
        limitedAccess: true
      },
    },
    {
      path: '*',
      redirect: '/'
    },

  ]
});

routes.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.limitedAccess)) {
    let vuex = JSON.parse(localStorage.getItem('vuex'))
    const orgsWithAccess = [289, 39, 2733, 411, 168, 464, 2609, 299, 158, 198, 536, 346, 172, 133, 163, 13, 10, 173, 3557]
    if (orgsWithAccess.indexOf(vuex.auth.user.orgID) === -1 && !vuex.auth.user.realm_access.roles.includes('ADMIN_ROLE')) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default routes;