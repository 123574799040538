// import Api from "@/utils/Api";
// eslint-disable-next-line no-unused-vars
import router from '@/router'
import request from "@/services/request";
import jwt_decode from "jwt-decode";//https://github.com/auth0/jwt-decode

export default {
    namespaced: true,
    state: {
        status: false,
        user: null,
        userInfo: null,
        loading: false,
        accessToken: null,
        refreshToken: null,
        error: null,
        blockedEnterData: false,
        blockedLK: false,
        blockedAll: false,
        blockedAdmin: false,
        blockLoading: false,
        replaceIdAdmin: null,
        replaceIdTypeOrg: null,
        replaceRoleIdAdmin: null,
        actualOrgStatus: null,
        loadingUserInfo: false,
        errorUserInfo: null
    },
    actions: {
        async login({commit, dispatch,rootState}, payload) {
            commit('LOADING', true)
            const res = await request({
                endpoint: `/api/login`,
                method: 'post',
                body: {
                    username: payload.username,
                    password: payload.password
                }
            })
            if (res.status === 200) {
                commit('LOADING', false)
                const decodeToken = jwt_decode(res.data.accessToken)
                if (!decodeToken)
                    dispatch('notifications/addNotification', {
                        notificationStatus: 'error',
                        notificationMessage: 'ошибка сервера',
                        timeout: 5000
                    }, {root: true})
                // localStorage.setItem('auth', JSON.stringify(data.data))
                commit('AUTHENTICATION_SUCCESS', {
                    'user': decodeToken,
                    'token': res.data.accessToken,
                    'refreshToken': res.data.refreshToken,
                    'status': true
                });
                dispatch('notifications/addNotification', {
                    notificationStatus: 'success',
                    notificationMessage: 'Вы вошли в систему',
                    timeout: 5000
                }, {root: true})
                // console.log("cc",rootState.spr.sprDataOrg.data.length , rootState.spr.sprDataRegion.data.length)
                // console.log("cc",rootState.spr.sprDataLanguages.data.length , rootState.spr.sprDataOksm.data.length)
                if (!rootState.spr.sprDataOrg.data.length || !rootState.spr.sprDataRegion.data.length || !rootState.spr.sprDataLanguages.data.length || !rootState.spr.sprDataOksm.data.length)
                    dispatch('spr/dataForSpr', {}, {root: true})
                await dispatch('getUserInfo');
            } else {
                dispatch('notifications/addNotification', {
                    notificationStatus: 'error',
                    notificationMessage: res.status === 400 ? 'Неверный пароль' : res.status === 404 ? 'Неверный логин' : 'Проверьте свои учетные данные, логин или пароль ',
                    timeout: 5000
                }, {root: true})
                commit('LOADING', false)
                commit('ERROR', res.error.data)
            }
        },
        async blockEnterData({commit,dispatch}) {
            commit('BLOCK_USER_DATA', true)
            await dispatch('getUserInfo');
        },
        async getUserInfo({commit, dispatch, state}) {
            commit('USER_INFO_LOADING', true);
            //console.log(state)
            const res = await request({
                endpoint: `/api/directory/organization/${Number.parseInt(state.user.orgID)}`,
                method: 'get'
            })
            if (res.status === 200) {
                commit('USER_INFO_SUCCESS', res.data);
                // if (res.data.userStatus === 'ACTIVE')
                //     commit('BLOCK_USER_DATA', false)
                // else
                //     commit('BLOCK_USER_DATA', true)
                commit('USER_INFO_LOADING', false);
            } else {
                // console.log("error userINfo", res.error.data)
                dispatch('notifications/addNotification', {
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка получения данных по пользователю',
                    timeout: 5000
                }, {root: true})
                commit('USER_INFO_ERROR', res.error.data);
                commit('USER_INFO_LOADING', false);
            }
        },
        replaceIdAdmin({commit}, payload) {
            commit('REPLACE_ID_ADMIN', payload.orgID);
            commit('REPLACE_ID_TYPE_ORG_ADMIN', payload.sprOrgType);
            commit('REPLACE_ROLE_ID_ADMIN', payload.role)
        },
        async logout({commit}) {
            commit('LOG_OUT');
            localStorage.removeItem('auth');
            await router.replace('/login');
            commit('LOADING', false)
        },
        async blockEnter({dispatch}, payload) {
            if (!payload.status) {
                // const blockEnter = await Api.blockEnter(payload.sprOrgId);
                // if (blockEnter.status === 200) {
                dispatch('getActualOrgStatus');
                // }
            } else {
                // const unblockEnter = await Api.unblockEnter(payload.sprOrgId);
                // if (unblockEnter.status === 200) {
                dispatch('getActualOrgStatus');
                // }
            }
        },
        blockOrganization({commit}, payload){
            commit('USER_INFO_SUCCESS', payload)
        },
        saveNewFileInfo({commit}, payload) {
            commit('NEW_FILE_INFO', payload)
        }
    },
    mutations: {
        ['AUTHENTICATION_SUCCESS'](state, payload) {
            state.status = payload.status;
            state.accessToken = payload.token;
            state.refreshToken = payload.refreshToken;
            state.user = payload.user;
        },
        ['REFRESH_TOKEN_SUCCESS'](state, payload) {
            state.status = payload.status;
            state.accessToken = payload.token;
            state.refreshToken = payload.refreshToken;
            state.errorRefreshToken = null;
            state.user = payload.user;
        },
        ['USER_INFO_SUCCESS'](state, payload) {
            state.userInfo = payload;
            state.errorUserInfo = null;
        },
        ['LOG_OUT'](state) {
            state.status = false;
            state.user = null;
            state.error = null;
            state.accessToken = null;
            state.userInfo = null;
            state.refreshToken = null;
            state.errorUserInfo = null;
        },
        ['ERROR'](state, payload) {
            state.error = payload;
            state.status = false;
        },
        ['LOADING'](state, payload) {
            state.loading = payload
        },
        ['USER_INFO_ERROR'](state, payload) {
            state.errorUserInfo = payload;
        },
        ['USER_INFO_LOADING'](state, payload) {
            state.loadingUserInfo = payload
        },
        ['REFRESH_TOKEN_ERROR'](state, payload) {
            state.errorRefreshToken = payload;
            state.status = false;
        },
        ['REFRESH_TOKEN_LOADING'](state, payload) {
            state.loadingRefreshToken = payload
        },
        ['BLOCK_USER_DATA'](state, payload) {
            state.blockedEnterData = payload
        },
        ['BLOCK_LK_USER'](state, payload) {
            state.blockedLK = payload
        },
        ['BLOCK_ALL_USER'](state, payload) {
            state.blockedAll = payload
        },
        ['BLOCK_ADMIN'](state, payload) {
            state.blockedAdmin = payload
        },
        ['BLOCK_LOADING'](state, payload) {
            state.blockLoading = payload
        },
        ['REPLACE_ID_ADMIN'](state, payload) {
            state.replaceIdAdmin = payload
        },
        ['REPLACE_ID_TYPE_ORG_ADMIN'](state, payload) {
            state.replaceIdTypeOrg = payload
        },
        ['REPLACE_ROLE_ID_ADMIN'](state, payload) {
            state.replaceRoleIdAdmin = payload
        },
        ['ACTUAL_ORG_STATUS'](state, payload) {
            state.actualOrgStatus = payload
        },
        ['NEW_FILE_INFO'](state, payload) {
            state.userInfo.sprOrg.scanFile = payload
        }
    },
    getters: {
        authData(state) {
            return state.user
        },
        getAuthUserData(state) {
            return state.userInfo
        },
        getAuthUserDataLoading(state) {
            return state.loadingUserInfo
        },
        getAuthUserDataError(state) {
            return state.errorUserInfo
        },
        getAuthLoading(state) {
            return state.loading
        },
        authToken(state) {
            return state.accessToken
        },
        authStatus(state) {
            return state.status;
        },
        get_actual_org_status(state) {
            return state.actualOrgStatus;
        },
        get_blockedEnterData(state) {
            return state.blockedEnterData
        },
        get_blockedLK(state) {
            return state.blockedLK
        },
        get_blockedAll(state) {
            return state.blockedAll
        },
        get_blockedAdmin(state) {
            return state.blockedAdmin
        },
        get_blockedLoading(state) {
            return state.blockedLoading
        },
        get_replace_id_admin(state) {
            return state.replaceIdAdmin
        },
        get_replace_role_id_admin(state) {
            return state.replaceRoleIdAdmin
        },
        get_replace_id_type_org_admin(state) {
            return state.replaceIdTypeOrg
        },
    },
}