<template>
    <v-app>
        <CNotificationsFromStore/>
<!--        <nav-bar/>-->
<!--      <top-bar-old-version/>-->
      <MainHeader />
        <v-main style="background-color: #F5F6F8;">
            <v-container>
                <router-view/>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import CNotificationsFromStore from "@/components/CNotificationsFromStore";
    // import TopBar from "@/components/Navigation/TopBarOldVersion";
    import {mapActions, mapGetters} from 'vuex'
    // import axios from 'axios'
    // import NavBar from "@/components/Navigation/navBar";
    //import TopBarOldVersion from "@/components/Navigation/TopBarOldVersion";
    import MainHeader from "@/components/Navigation/MainHeader";

    export default {
        name: 'App',
        components: {
          MainHeader,
          //TopBarOldVersion,
          // NavBar,
            // TopBar,
          CNotificationsFromStore
        },
      props: {
        keycloak: Object
      },
        data() {
            return {
                showModal: true
            }
        },
        computed: {
            ...mapGetters('auth', {authStatus: 'authStatus'})
        },
      mounted() {
        this.setAuthData(this.keycloak);
      },
      created: function () {

            // if (this.authStatus)
            //     this.getUserInfo()
            // axios.interceptors.response.use(undefined, err => {
            //     return new Promise(/*function (resolve, reject)*/() => {
            //         if (process.env.NODE_ENV === 'development') {
            //             console.log("err.request", err.request)
            //             console.log("err.response", err.response)
            //             console.log("err.config", err.config)
            //         }
            //         if (err.request.status === 401 && err.isAxiosError && err.config) {
            //             this.storeLogout()
            //         }
            //         throw err.response;
            //     });
            // });
        },
        methods: {
            ...mapActions('keycloak', {setAuthData: 'setAuthData'}),
            ...mapActions('auth', {storeLogout: 'logout'}),
            ...mapActions('auth', {getUserInfo: 'getUserInfo'}),
        }
    }
</script>

<style lang="sass">
    @media (min-width: 1264px)
    .container
        max-width: 1420px !important

    .link-toMain, .link-exitOrg
        display: inline-block
        margin: 15px 0
        font-size: 1.1rem
        font-weight: bold

    .link-toMain:hover, .link-exitOrg:hover
        text-decoration: underline

    .link-exitOrg
        margin-left: 40px

</style>