import axios from 'axios'
import utils from "@/utils/utils";
import store from "@/store/index.js";

export default {
    async blockEnter(sprOrgId) {
        return axios.get(`/api/userProfile/blockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
    },
    async unblockEnter(sprOrgId) {
        return axios.get(`/api/userProfile/unblockPrint?sprOrgID=${sprOrgId}`, {headers: utils.authHeader()})
    },
    async dataTable(request, params = '?orgID='+store.getters['auth/getAuthUserData'].sprOrg.orgID) {
        return axios.get('/' + request.link + params,  {headers: utils.authHeader()})
    },
    async saveTable(request) {
        return axios.post('/' + request.link, {
            ...request.data,
        }, {headers: utils.authHeader()})
    },
    async updateTable(request) {
        return axios.post('/' + request.link, {
            ...request.data,
        }, {headers: utils.authHeader()})
    },
    async deleteFromDataTable(request) {
        return axios.get('/' + request.link+'?id='+request.id,  {headers: utils.authHeader()})
    },

    async sprDataLoad(arr) {
        let request = []
        arr.forEach(el => {
            request.push(axios.get(`/api/spr/${el}/getAll`, {headers: utils.authHeader()}))
        })
        return axios.all(request)
    },
}