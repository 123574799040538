import Api from "@/utils/Api";
export  default {
    namespaced: true,
    state: {
        sprDataOrg: {data: [], counter: 0},
        sprDataOrgLoading: false,
        sprDataOrgError: null,
        sprDataRegion: {data: [], counter: 0},
        sprDataRegionLoading: false,
        sprDataRegionError: null,
        sprDataSpec: {data: [], counter: 0},
        sprDataSpecLoading: false,
        sprDataSpecError: null,
        sprDataUsl: {data: [], counter: 0},
        sprDataUslLoading: false,
        sprDataUslError: null,
        sprDataFf: {data: [], counter: 0},
        sprDataFfLoading: false,
        sprDataFfError: null,
        sprDataFo: {data: [], counter: 0},
        sprDataFoLoading: false,
        sprDataFoError: null,
        sprDataUgs: {data: [], counter: 0},
        sprDataUgsLoading: false,
        sprDataUgsError: null,
        sprDataLanguages: {data: [], counter: 0},
        sprDataLanguagesLoading: false,
        sprDataLanguagesError: null,
        sprDataOksm: {data: [], counter: 0},
        sprDataOksmLoading: false,
        sprDataOksmError: null,
        sprDataOlympiadProfile: {data: [], counter: 0},
        sprDataOlympiadProfileLoading: false,
        sprDataOlympiadProfileError: null,
    },
    actions:{
        async dataForSpr({commit}){
            commit('SET_DATA_SPR_LOADING', true)
            let typesSpr = ['region','org','languages', 'oksm', 'olympiadProfile']
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getRegion'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllOrgs'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllLanguages'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllOksm'})
            commit('SET_DATA_SPR_LOADING', {data: true,type: 'getAllOlympiadProfile'})


            let [sprResRegion,sprResOrg,sprResLanguages,sprResOksm,sprResOlympiadProfile] = await Api.sprDataLoad(typesSpr)
            if (sprResRegion.status===200){
                commit('SET_DATA_SPR', {data: sprResRegion.data,type: 'getRegion'})
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getRegion'})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getRegion'})
                commit('SET_DATA_SPR_ERROR', {data: sprResRegion.error,type: 'getRegion'})
            }
            if (sprResOrg.status===200){
                commit('SET_DATA_SPR',  {data: sprResOrg.data,type: 'getAllOrgs'})
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOrgs'})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOrgs'})
                commit('SET_DATA_SPR_ERROR',  {data: sprResOrg.error,type: 'getAllOrgs'})
            }

            if (sprResLanguages.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllLanguages'})
                commit('SET_DATA_SPR',{data: sprResLanguages.data,type: 'getAllLanguages'})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllLanguages'})
                commit('SET_DATA_SPR_ERROR', {data: sprResLanguages.error,type: 'getAllLanguages'})
            }
            if (sprResOksm.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOksm'})
                commit('SET_DATA_SPR',{data: sprResOksm.data,type: 'getAllOksm'})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOksm'})
                commit('SET_DATA_SPR_ERROR', {data: sprResOksm.error,type: 'getAllOksm'})
            }
            if (sprResOlympiadProfile.status===200){
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOlympiadProfile'})
                commit('SET_DATA_SPR',{data: sprResOlympiadProfile.data,type: 'getAllOlympiadProfile'})
            }
            else {
                commit('SET_DATA_SPR_LOADING', {data: false,type: 'getAllOlympiadProfile'})
                commit('SET_DATA_SPR_ERROR', {data: sprResOlympiadProfile.error,type: 'getAllOlympiadProfile'})
            }
        },
    },
    mutations:{
        ['SET_DATA_SPR'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUsl = {data: payload.data, counter: payload.data.length}
                    break
                case 'getRegion':
                    state.sprDataRegion = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllSpec':
                    state.sprDataSpec = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllOrgs':
                    state.sprDataOrg ={data: payload.data, counter: payload.data.length}
                    break
                case 'getAllFF':
                    state.sprDataFf = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllFo':
                    state.sprDataFo = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllUgs':
                    state.sprDataUgs = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllOksm':
                    state.sprDataOksm = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllLanguages':
                    state.sprDataLanguages = {data: payload.data, counter: payload.data.length}
                    break
                case 'getAllOlympiadProfile':
                    state.sprDataOlympiadProfile = {data: payload.data, counter: payload.data.length}
                    break
            }
        },
        ['SET_DATA_SPR_LOADING'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUslLoading = payload.data
                    break
                case 'getRegion':
                    state.sprDataRegionLoading = payload.data
                    break
                case 'getAllSpec':
                    state.sprDataSpecLoading = payload.data
                    break
                case 'getAllOrgs':
                    state.sprDataOrgLoading = payload.data
                    break
                case 'getAllFF':
                    state.sprDataFfLoading = payload.data
                    break
                case 'getAllFo':
                    state.sprDataFoLoading = payload.data
                    break
                case 'getAllUgs':
                    state.sprDataUgsLoading = payload.data
                    break
                case 'getAllOksm':
                    state.sprDataOksmLoading = payload.data
                    break
                case 'getAllLanguages':
                    state.sprDataLanguagesLoading = payload.data
                    break
                case 'getAllOlympiadProfile':
                    state.sprDataOlympiadProfileLoading = payload.data
                    break
            }
        },
        ['SET_DATA_SPR_ERROR'](state,payload){
            switch (payload.type) {
                case 'getUsl':
                    state.sprDataUslError = payload.data
                    break
                case 'getRegion':
                    state.sprDataRegionError = payload.data
                    break
                case 'getAllSpec':
                    state.sprDataSpecError = payload.data
                    break
                case 'getAllOrgs':
                    state.sprDataOrgError = payload.data
                    break
                case 'getAllFF':
                    state.sprDataFfError = payload.data
                    break
                case 'getAllFo':
                    state.sprDataFoError = payload.data
                    break
                case 'getAllUgs':
                    state.sprDataUgsError = payload.data
                    break
                case 'getAllOksm':
                    state.sprDataOksmError = payload.data
                    break
                case 'getAllLanguages':
                    state.sprDataLanguagesError = payload.data
                    break
                case 'getAllOlympiadProfile':
                    state.sprDataOlympiadProfileError = payload.data
                    break
            }
        },
    },
    getters:{
        get_spr_data_usl(state){
            return state.sprDataUsl
        },
        get_spr_data_usl_loading(state){
            return state.sprDataUslLoading
        },
        get_spr_data_usl_error(state){
            return state.sprDataUslError
        },
        get_spr_data_region(state){
            return state.sprDataRegion
        },
        get_spr_data_region_loading(state){
            return state.sprDataRegionLoading
        },
        get_spr_data_region_error(state){
            return state.sprDataRegionError
        },
        get_spr_data_ugs(state){
            return state.sprDataUgs
        },
        get_spr_data_ugs_loading(state){
            return state.sprDataUgsLoading
        },
        get_spr_data_ugs_error(state){
            return state.sprDataUgsError
        },
        get_spr_data_spec(state){
            return state.sprDataSpec
        },
        get_spr_data_spec_loading(state){
            return state.sprDataSpecLoading
        },
        get_spr_data_spec_error(state){
            return state.sprDataSpecError
        },
        get_spr_data_org(state){
            return state.sprDataOrg
        },
        get_spr_data_org_main(state){
            return state.sprDataOrg
            // return state.sprDataOrg.data.filter(el => el.orgStatus == '1');
        },
        get_spr_data_org_loading(state){
            return state.sprDataOrgLoading
        },
        get_spr_data_org_error(state){
            return state.sprDataOrgError
        },
        get_spr_data_ff(state){
            return state.sprDataFf
        },
        get_spr_data_ff_loading(state){
            return state.sprDataFfLoading
        },
        get_spr_data_ff_error(state){
            return state.sprDataFfError
        },
        get_spr_data_fo(state){
            return state.sprDataFo
        },
        get_spr_data_fo_loading(state){
            return state.sprDataFoLoading
        },
        get_spr_data_fo_error(state){
            return state.sprDataFoError
        },
        get_spr_data_oksm(state){
            return state.sprDataOksm
        },
        get_spr_data_oksm_loading(state){
            return state.sprDataOksmLoading
        },
        get_spr_data_oksm_error(state){
            return state.sprDataOksmError
        },
        get_spr_data_olympiad(state){
            return state.sprDataOlympiadProfile
        },
        get_spr_data_olympiad_loading(state){
            return state.sprDataOlympiadProfileLoading
        },
        get_spr_data_olympiad_error(state){
            return state.sprDataOlympiadProfileError
        },
        get_spr_data_languages(state){
            return state.sprDataLanguages
        },
        get_spr_data_languages_loading(state){
            return state.sprDataLanguagesLoading
        },
        get_spr_data_languages_error(state){
            return state.sprDataLanguagesError
        },
       
        
    }
}