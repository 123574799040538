export default {
    namespaced: true,
    state: {
        keycloak: null,
    },
    mutations: {
        ['SET_AUTH_DATA'](state, data) {
            state.keycloak = data;
        },
    },
    actions: {
        setAuthData({commit}, payload) {
            commit('SET_AUTH_DATA', payload);
            commit('auth/AUTHENTICATION_SUCCESS', {
                'user': payload.tokenParsed,
                'token': payload.token,
                'refreshToken': payload.refreshToken,
                'status': true
            }, { root: true })
        },
        keycloakLogout({commit, state}) {
            if (state.keycloak) {
                console.log("1", state.keycloak)
                state.keycloak.logout()
                commit('SET_AUTH_DATA', null)
                commit('auth/AUTHENTICATION_SUCCESS', null, {root:true});
                localStorage.removeItem('auth');
            }
        }
    },
    getters: {
        getAuthData(state) {
            return state.keycloak
        },
    }
}